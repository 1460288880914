import React, { useEffect, useState, useRef } from 'react';

import SaveAsIcon from '@mui/icons-material/SaveAs';

function ErrorHandler(props){
    const session = props?.session;
    const sessionUser = props?.sessionUser;
    const viewType = session?.env?.viewport?.viewType;

    const reporting = session?.reporting;
    const selectedReport = reporting?.data?.selectedReport;
    const updateReporting = props?.session?.reporting?.setData;

    const categorySelected = props?.session?.individual?.data?.[selectedReport?.branch]?.[selectedReport?.stem]?.[0];

    console.log(session);
    return (
        <div className="reportErrorBlock g f cC">
            <div className="prompt f g cC fR dP">
                You have unsaved changes to a report you've started editing.
                Please confirm how you'd like to proceed prior to viewing any other report.
            </div>
            <div className="actionBar f g cC fR dP">
                <div
                    className="btn cancel f cC bR p bold alt"
                    onClick={()=>{
                        session?.set("reporting", "selectedReport", {...reporting?.data?.tempReport});
                        session?.env?.setOverlay(JSON.stringify(reporting?.data?.tempReport) === JSON.stringify(reporting?.data?.reset) ? "criteriaHandler" : undefined);
                    }}
                >
                    Clear Changes
                </div>
                <div
                    className="btnWIcon f cC bR p bold e alt"
                    onClick={()=>{
                        session?.env?.setOverlay("criteriaHandler");
                        session?.set("reporting", "selectedReport.builder.currentView", selectedReport?.query ? "properties" : "requirements");
                    }}
                >
                    <div className="f cC">
                        Save Changes
                    </div>
                    <SaveAsIcon/>
                </div>
            </div>
        </div>
    )
};

export default ErrorHandler;